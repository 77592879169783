
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
body{
    font-family: "Playfair";
}
@font-face {
    font-family: 'Tahoma'; 
    src: url('../public/fonts/TAHOMABD.TTF') format('truetype');
}
@font-face {
    font-family: 'Plus Jakarta Sans'; 
    src: url('../public/fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Sacramento'; 
    src: url('../public/fonts/Sacramento-Regular.ttf') format('truetype');
}
.bg-gradient {
    background: linear-gradient(90deg, rgba(0, 117, 255, 0.7) 1.8%, rgba(24, 118, 228, 0.7) 1.81%, rgba(218, 39, 189, 0.7) 98.35%);
}
.animate-pulse {
    animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.tahoma-font {
    font-family: 'Tahoma', sans-serif;
}
.jakarta{
    font-family: 'Plus Jakarta Sans',sans-serif;
}
.sacramento{
    font-family: 'Sacramento',serif;
}
